var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EndPoints } from '../../../Api';
import { FORMAT_YYYY_MM_DD } from '../../../constant/date';
import { fDate, fDateTime, getMonthName } from '../../../utils';
import axios from '../../../utils/axios';
export var getAnalytics = createAsyncThunk('analytics/GETALL', function (query) { return __awaiter(void 0, void 0, void 0, function () {
    var data, api, response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                api = void 0;
                if (query) {
                    api = "/statistics".concat(query);
                }
                else {
                    api = "/statistics";
                }
                return [4 /*yield*/, axios.get(api)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_1 = _a.sent();
                return [2 /*return*/, Promise.reject(err_1.message ? err_1.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var getNbStatClientResponsesByOffice = createAsyncThunk('analytics/GETNBSTATCLIENTRESPONSESBYOFFICE', function (query) { return __awaiter(void 0, void 0, void 0, function () {
    var data, api, response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                api = void 0;
                if (query) {
                    api = "/statistics/nb-stat-client-responses-by-office".concat(query);
                }
                else {
                    api = "/statistics/nb-stat-client-responses-by-office";
                }
                return [4 /*yield*/, axios.get(api)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data.nbStatClientResponsesByOffice];
                }
                throw new Error(response.statusText);
            case 3:
                err_2 = _a.sent();
                return [2 /*return*/, Promise.reject(err_2.message ? err_2.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var getAnalyticsBalanced = createAsyncThunk('analytics/balance', function (payload) {
    if (payload === void 0) { payload = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var data, office, startDate, endDate, params, response, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    office = payload.office, startDate = payload.startDate, endDate = payload.endDate;
                    params = __assign(__assign({}, (office ? { office: office === null || office === void 0 ? void 0 : office._id } : {})), (startDate && endDate
                        ? {
                            startDate: fDate(startDate, 'yyyy-MM-dd'),
                            endDate: fDate(endDate, 'yyyy-MM-dd'),
                        }
                        : {}));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, axios.get('/statistics/balance', { params: params })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.data];
                case 3:
                    data = _a.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, data.data];
                    }
                    throw new Error(response.statusText);
                case 4:
                    err_3 = _a.sent();
                    return [2 /*return*/, Promise.reject(err_3.message ? err_3.message : data === null || data === void 0 ? void 0 : data.message)];
                case 5: return [2 /*return*/];
            }
        });
    });
});
export var getBalancePerMonth = createAsyncThunk('balance-per-month', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var year, month, order, orderBy, startDate, endDate, data, params, response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                year = payload.year, month = payload.month, order = payload.order, orderBy = payload.orderBy, startDate = payload.startDate, endDate = payload.endDate;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                params = __assign(__assign(__assign({ year: year === null || year === void 0 ? void 0 : year.getFullYear().toString() }, (month ? { month: month.getMonth() } : {})), (startDate && endDate
                    ? {
                        startDate: fDate(startDate, FORMAT_YYYY_MM_DD),
                        endDate: fDate(endDate, FORMAT_YYYY_MM_DD),
                        year: undefined,
                        month: undefined,
                    }
                    : {})), { sort: order === 'desc' ? "-".concat(orderBy) : orderBy });
                return [4 /*yield*/, axios.get(startDate ? 'statistics/balance-per-office' : 'statistics/balance-per-month', {
                        params: params,
                    })];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 3:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 4:
                err_4 = _a.sent();
                return [2 /*return*/, Promise.reject(err_4.message ? err_4.message : data === null || data === void 0 ? void 0 : data.message)];
            case 5: return [2 /*return*/];
        }
    });
}); });
export var getBalancePerMonthPerOffice = createAsyncThunk('balance-per-month-per-office', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var year, month, data, params, response, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                year = payload.year, month = payload.month;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                params = __assign({ year: year === null || year === void 0 ? void 0 : year.getFullYear().toString() }, (month ? { month: month.getMonth() } : {}));
                return [4 /*yield*/, axios.get('statistics/balance-per-month-per-office', {
                        params: params,
                    })];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 3:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 4:
                err_5 = _a.sent();
                return [2 /*return*/, Promise.reject(err_5.message ? err_5.message : data === null || data === void 0 ? void 0 : data.message)];
            case 5: return [2 /*return*/];
        }
    });
}); });
export var getNbStatClientResponsesByStatClientByOffice = createAsyncThunk('analytics/nbStatClientResponsesByStatClientByOffice', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, startDate, endDate, params, response, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                startDate = payload.startDate, endDate = payload.endDate;
                params = __assign({}, (startDate && endDate
                    ? { startDate: fDate(startDate, 'yyyy-MM-dd'), endDate: fDate(endDate, 'yyyy-MM-dd') }
                    : {}));
                return [4 /*yield*/, axios.get('statistics/nb-stat-client-response', { params: params })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_6 = _a.sent();
                return [2 /*return*/, Promise.reject(err_6.message ? err_6.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var getNbStatsClientResponseByMonth = createAsyncThunk('statistic/statsClientResponseByMonth', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, month, year, params, response, err_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                month = payload.month, year = payload.year;
                params = __assign(__assign({}, (year ? { year: fDateTime(year, 'yyyy') } : {})), (month ? { month: fDateTime(month, 'LLLL').toLowerCase() } : {}));
                return [4 /*yield*/, axios.get('statistics/nb-stat-client-response-by-stat-client', {
                        params: params,
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_7 = _a.sent();
                return [2 /*return*/, Promise.reject(err_7.message ? err_7.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var getKpiStatResponse = createAsyncThunk('/statistic/kpiStatsResponse', function (_a) {
    var kpiId = _a.kpiId, startDate = _a.startDate, endDate = _a.endDate;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, params, response, err_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    params = __assign({ kpiId: kpiId }, (startDate && endDate
                        ? {
                            startDate: fDate(startDate, FORMAT_YYYY_MM_DD),
                            endDate: fDate(endDate, FORMAT_YYYY_MM_DD),
                        }
                        : {}));
                    return [4 /*yield*/, axios.get(EndPoints.statistic.kpi, { params: params })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.data];
                case 2:
                    data = _b.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, data.data];
                    }
                    throw new Error(response.statusText);
                case 3:
                    err_8 = _b.sent();
                    return [2 /*return*/, Promise.reject(err_8.message ? err_8.message : data === null || data === void 0 ? void 0 : data.message)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var getKpiStatResponseByRange = createAsyncThunk('statistic/kpi-stat-range-by-date', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var data, kpiId, year, month, params, response, err_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                kpiId = payload.kpiId, year = payload.year, month = payload.month;
                params = __assign({ kpiId: kpiId, year: fDate(year, 'yyyy') }, (month ? { month: getMonthName(month).toLocaleLowerCase() } : {}));
                return [4 /*yield*/, axios.get(EndPoints.statistic.kpiRangeDate, { params: params })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_9 = _a.sent();
                return [2 /*return*/, Promise.reject(err_9.message ? err_9.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
